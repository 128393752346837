<template>

  <mfc-input-box :errors="cmpErrors">
    <div ref="dropArea"
         row=""
         wrap=""
         class="drop-area"
         :class="{highlight, uploading, 'show': showOnly, 'with-img': imgSrc}"
         @click="imgSrc && showOnly ? false : $refs.file.click()"
         @dragenter.stop.prevent="startDrag"
         @dragover.stop.prevent="startDrag"
         @dragleave.stop.prevent="stopDrag"
         @drop.stop.prevent="handleDrop">
      <span class="label" v-if="!imgSrc">{{label}}</span>
      <div class="icon" row="cn" v-if="!imgSrc"><i class="mfcicon-add-calendar"></i></div>
      <img :src="imgSrc" v-if="imgSrc" alt="attachment">
      <input type="file"
             ref="file"
             accept="image/*"
             v-if="!showOnly"
             @change="handleFile($event.target.files)"
             :disabled="uploading || attrs.disabled"
             v-bind="fileInputAttrs">
      <input type="hidden"
             v-if="!showOnly"
             :value="cmpValue"
             :name="name">
    </div>
  </mfc-input-box>
</template>

<script>
  import MfcInputBox from './MfcInputBox'
  import first from 'lodash/first'
  import isEmpty from 'lodash/isEmpty'
  import inputMixin from "../../mixins/inputMixin"

  export default {
    name: "MfcAttachmentUpload",
    mixins: [inputMixin],
    components: {MfcInputBox},
    props: {
      value: {
        type: [File, Object],
      },
      src: {
        type: [String],
      },
      kind: {
        type: [String],
      },
      type: {
        type: [String],
      },
      showOnly: {
        type: Boolean,
        default: false
      },
      label: {
        type: String
      }
    },
    data() {
      return {
        uploadedSrc: null,
        highlight: false,
        fileId: null,
      }
    },
    computed: {
      imgSrc() {
        return this.uploadedSrc || this.src
      },
      fileInputAttrs() {
        const {name, disabled, ...attrs} = this.attrs
        return attrs
      },
      uploading: {
        get() {
          return this.$store.state.attachmentUploading
        },
        set(v) {
          this.$store.commit('SET_STATE', {path: ['attachmentUploading'], value: v})
        }
      }
    },
    methods: {
      async handleFile(files) {
        this.cmpErrors.length = 0

        const file = first(files)
        if (!file || this.uploading) return

        try {
          this.uploading = true

          const formData = new FormData()

          formData.append('attachment[file]', file)
          formData.append('attachment[kind]', this.kind)
          formData.append('attachment[type]', this.type)

          const {data} = await this.$axios.post(this.$routes.attachments_path({format: "json"}), formData)

          if (data.resource) {
            this.cmpValue= data.resource.id
            this.uploadedSrc = data.resource.url
          }
        } catch (e) {
          this.cmpErrors = ['Непредвиденная ошибка']
        } finally {
          this.uploading = false
        }
      },
      handleDrop(e) {
        this.stopDrag()

        if(this.showOnly) return

        const dt = e.dataTransfer
        const files = dt.files

        if (!isEmpty(files)) {
          this.handleFile(files)
        }
      },
      startDrag() {
        this.highlight = true
      },

      stopDrag() {
        this.highlight = false
      },
    },
    created () {
      if (this.value) this.cmpValue = this.value.id
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/variables';

  .category_image {
    .drop-area {
      height: 128px;
      width: 180px;
    }
    .drop-area img{
      height: 128px;
      width: 180px;
      object-fit: cover;
      object-position: center;
    }
  }

  .drop-area {
    position: relative;
    border: 1px solid whitesmoke;
    border-radius: $default-border-radius;
    background: whitesmoke;
    height: 128px;
    width: 180px;
    overflow: hidden;
    transition: opacity .2s;

    .label {
      color: #878787;
      align-self: flex-start;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
    }

    .icon {
      $size: 48px;
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color: #ffffff;
      align-self: flex-end;
      font-size: 19px;
      color: #948787;
    }

    &:not(.with-img) {
      padding: 11px;
    }

    &:not(.show):not(.uploading) {
      cursor: pointer;

      &.highlight, &:hover {
        background: white;
        border-radius: 8px;
        border: 1px solid #dadada;

        .icon {
          background-color: whitesmoke;
        }

      }
    }

    input {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: $passport-uploader-zindex;
    }
  }
</style>
