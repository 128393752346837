import toastr from 'toastr'
import uniqueId from 'lodash/uniqueId'

export default {
  name: 'uploaderHelperMixin',
  data() {
    return {
      validFileTypes: ".mp4, .avi, .mov, .mkv, .zip, .rar, .tar, .gz, .7z, .tiff, .jpg, .jpeg, .png, .doc, .docx, .pdf, .txt, .xlsx, .xls, .ods"
    }
  },
  methods: {
    validFile(file) {
      if (this.converter(file.size) > 50) {
        toastr.error('Файл большого размера (максимальный размер - 50 MB)')
        return false
      }
      if (!this.validFileTypes.includes(this.fileType(file.name.toLowerCase()))) {
        toastr.error('Файл имеет неверный формат. Разрешено загружать - jpg, jpeg, png, mp4, avi, mov, mkv, zip, rar, tar, gz, 7z, jpeg, png, tiff, doc, docx, pdf')
        return false
      }
      return true
    },
    converter(size) {
      return Math.round((size / (1024 * 1024)) * 100) / 100
    },
    fileType(filename) {
      const array = filename.split('.')
      return array[array.length - 1]
    },
    fakeFileConfiguration(file) {
      return {
        description: file.name,
        for_delete: false,
        id: uniqueId(),
        loaded: true
      }
    },
    replaceObjectInArray(array, newObject, oldObject) {
      return array.map(obj => {
        if (obj.id === oldObject.id) {
          return newObject
        }
        return obj
      })
    }
  }
}
