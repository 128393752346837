<template>
  <div class="not-border">
    <div
       ref="dropArea"
       col="fs fs"
       wrap="">
      <div class="attachment-buttons">
        <div v-if="refuze">
          <a
            class="button primary attachment-button"
            :class="{disabled: !$store.state.refuze}"
            :href="`${updatePath}?state=refuze&refuze=${$store.state.refuze}&` + documents.map(doc => `state_comment_document_ids[]=${doc.id}`).join('&')"
            name="button">
            Отказать
          </a>
          <button type="button" class="simple attachment-button" @click="$store.commit('SET_STATE', {path: ['refuzePartialShow'], value: false})"> Отмена</button>
          <button type="button" class="simple" @click="imgSrc ? false : $refs.file.click()"> Прикрепить</button>
        </div>
        <div v-else>
          <button class="button primary attachment-button" type="submit" name="button" >Отправить</button>
          <button type="button" class="simple" @click="imgSrc ? false : $refs.file.click()"> Прикрепить</button>
        </div>
        <tippy toSelector=".cssSelector">
          Объем загружаемых файлов не более 50 Мб.
          Допустимые форматы:
          Видео: mp4, avi, mov. 
          Архивы: zip, rar, tar, 7z. 
          Изображения: tiff, jpg, jpeg, png. 
          Документы: doc, docx, pdf, txt, xlsx, xls, ods. 
          Иные форматы файлов не обрабатываются приложением.
        </tippy>

        <i class="mfcicon-question cssSelector"></i>
      </div>
    </div>
    <div class="documents">
      <div class="document-wrapper" v-for="doc in documents" :key="doc.id">
        <div class="document" row="fc cn">
          <i class="mfcicon-text-snippet document-text-snippet-icon position-center"></i>    
          <a class="link" :href="doc.url" target="_blank" v-if="doc.url">{{doc.description}}</a>
          <p class="fake-link" v-else>{{doc.description}}</p>
          <i class="mfcicon-delete document-delete-icon position-center" @click="deleteDocument(doc)"></i>
          <pulse-loader v-if="doc.loaded" color="#d4391d" size="7px"></pulse-loader>
        </div>
        <span class="error" v-if="doc.fail">{{doc.fail}}</span>
      </div>
    </div>

    <div class="file-errors">
      <p class="file-error" v-for="fileError in fileErrors" :key="fileError" row="fc cn">{{fileError}}</p>
    </div>

    <input type="file"
           ref="file"
           accept=".mp4, .avi, .mov, .mkv, .zip, .rar, .tar, .gz, .7z, .tiff, .jpg, .jpeg, .png, .doc, .docx, .pdf"
           v-show="false"
           @change="handleFile($event.target.files)"
           :disabled="uploading || $attrs.disabled"
           v-bind="fileInputAttrs">
    <input type="hidden" v-for="doc in documents" :key="doc.id" :value="doc.id" :name="'state_comment_document_ids[]'">
  </div>

</template>

<script>
  import first from 'lodash/first'
  import findIndex from 'lodash/findIndex'
  import uploaderHelperMixin from '../../mixins/uploaderHelperMixin'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

  export default {
    name: 'StateCommentDocuments',
    components: {PulseLoader},
    mixins: [uploaderHelperMixin],
    props: {
      refuze: {
        type: Boolean,
        default: false
      },
      updatePath: {
        type: String
      }
    },
    data() {
      return {
        uploadedSrc: null,
        highlight: false,
        fileId: null,
        validFormatsFiles: ['mp4', 'avi', 'mov', 'mkv', 'zip', 'rar', 'tar', 'gz', '7z', 'tiff', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'ods'],
        fileErrors: [],
        documents: []
      }
    },
    computed: {
      imgSrc() {
        return this.uploadedSrc || this.src
      },
      fileInputAttrs() {
        const {...attrs} = this.attrs
        return attrs
      },
      uploading: {
        get() {
          return this.$store.state.attachmentUploading
        },
        set(v) {
          this.$store.commit('SET_STATE', {path: ['attachmentUploading'], value: v})
        }
      },
      allowSend() {
        return (this.$store.state.clarification && this.$store.state.clarification.length > 1) || (this.$store.state.resolved && this.$store.state.resolved.length > 1)
      }
    },
    methods: {
      async handleFile(files) {

        this.fileErrors = []

        const file = first(files)
        if (!file || this.uploading || !this.validFile(file)) return

        const fakeFile = this.fakeFileConfiguration(file)
        this.documents.push(fakeFile)

        if (this.fileErrors.length > 0) return

        try {
          this.uploading = true

          const formData = new FormData()

          formData.append('attachment[file]', file)
          formData.append('attachment[kind]', "claim_document")
          formData.append('attachment[type]', "Attachment::Document")

          const CancelToken = this.$axios.CancelToken
          const vm = this
          const {data} = await this.$axios.post(this.$routes.attachments_path({format: "json"}), formData, {
            cancelToken: new CancelToken(function executor(c) {
              vm.cancelRequest = c;
            })
          })
          this.documents = this.replaceObjectInArray(this.documents, data.resource, fakeFile)
        } catch (e) {
          this.documents.forEach(item => {
            if (item.id === fakeFile.id) {
              item.loaded = false
              item.for_delete = true
              item.fail = 'Произошла ошибка, попробуйте снова.'
            }
          });
          console.error("ERROR", e)
        } finally {
          this.uploading = false
        }
      },
      deleteDocument (doc) {
        if (!doc.for_delete) {
          if (this.cancelRequest) {
            this.cancelRequest()
          } else {
            return
          }
        }
        let idx = findIndex(this.documents, function(d) { return d.id == doc.id; });
        if (idx != -1) {
          this.documents.splice(idx, 1)
        }
      }
    }
  }
</script>

<style lang='scss' scoped>
  @import '../../assets/styles/variables';

  button.simple {
    margin-top: 19px;
  }

  .disable {
    margin: 0!important;
    padding: 0!important;
  }

  .documents {
    .document-wrapper {
      .document {
        display: flex;
        align-items: center;
        &-text-snippet-icon {
          font-size: 14px;
          margin-right: 9px;
        }
        .link {
          color: rgba(21, 43, 78, 1);
          font-size: 18px;
          cursor: pointer;

          &:hover {
            text-decoration: underline !important;
          }
        }
        .fake-link {
          margin: 0;
          color: rgba(21, 43, 78, 1);
          font-size: 18px;
        }
        &-delete-icon {
          cursor: pointer;
          margin-left: 7px;
          font-size: 14px;
          transition: .1s ease;

          &:hover {
            color: rgba(21, 43, 78, 1)
          }
        }
        .v-spinner {
          margin-left: 10px;
        }
      }

      .error {
        margin-top: 4px;
        font-size: 14px;
        line-height: 20px;
        color: rgb(255, 26, 26);
      }

      &:first-child {
        margin-top: 28px;
      }
      &:last-child {
        margin-bottom: 28px;
      }
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  .file-errors {
    margin-top: 5px;

    .file-error {
      font-size: 14px;
      line-height: 20px;
      color: #FF1A1A;
    }
  }

  .position-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
