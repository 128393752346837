<template>
  <div>
    <div v-if="multiple">
      <i class="mfcicon-download icon-download"></i>
      <button class="download-button" @click.prevent="downloadRecords">
        <span v-if="!isLoading">Скачать записи</span>
        <pulse-loader color="#878787" :loading="isLoading"></pulse-loader>
      </button>
    </div>
    <div v-else>
      <button class="button primary mini" @click.stop="downloadRecords">
        <span v-if="!isLoading">Скачать</span>
        <pulse-loader color="white" :loading="isLoading"></pulse-loader>
      </button>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { saveAs } from 'file-saver'

export default {
  name: 'RecordDownload',
  components: {
    PulseLoader
  },
  props: {
    urls: {
      type: Array,
      required: true
    },
    filename: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    downloadRecords() {
      if (this.isLoading) return

      this.isLoading = true
      var zip = new JSZip()

      function request(url, name) {
        return new Promise(function(resolve) {
          var httpRequest = new XMLHttpRequest()
          httpRequest.open("GET", url)
          httpRequest.responseType = 'blob'
          httpRequest.onload = function() {
            const blob = new Blob([this.response], { type: 'video/mp4' })
            zip.file(name, blob)
            resolve()
          }
          httpRequest.send()
        })
      }

      Promise.all(this.urls.map(function(url) {
        return request(url.link, url.name)
      }))
      .then(() => {
        zip.generateAsync({
          type: "blob"
        })
        .then((content) => {
          saveAs(content, this.filename)
        })
      })
      .finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss">
  .icon-download {
    color: #878787;
    margin-left: 20px;
  }
  .download-button {
    padding-left: 7px;
  }
</style>
