
import toastr from "toastr"

export default {
  props: {
    schedule: {
      type: Object
    }
  },
  methods: {
    validDate (cb, event) {
      if (!this.$moment(event.target.value, 'HH:mm', true).isValid()) {
        cb()
      }
    }
  },
  computed: {
    start () {
      if (this.schedule && this.$moment(this.schedule.start, 'HH:mm', true).isValid()) {
        return this.schedule.start
      }
    },
    end () {
      if (this.schedule && this.$moment(this.schedule.end, 'HH:mm', true).isValid()) {
        return this.schedule.end
      }
    }
  },
  watch: {
    start: function (value) {
      if (value && this.end && this.$moment(value, 'HH:mm', true) >= this.$moment(this.schedule.end, 'HH:mm', true)) {
        setTimeout(() => {
          this.schedule.start = ""
        }, 500)
        toastr.warning("Начало приёма должно быть раньше окончания приёма")
      }
    },
    end: function (value) {
      if (value && this.start && this.$moment(value, 'HH:mm', true) <= this.$moment(this.schedule.start, 'HH:mm', true)) {
        setTimeout(() => {
          this.schedule.end = ""
        }, 500)
        toastr.warning("Конец приёма должен быть позже начала приёма")
      }
    }
  }
}