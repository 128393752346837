<template>
  <div class="input-box" :class="{'with-error': withErrors, cleared: isCleared}">
    <slot></slot>
    <ul class="errors" v-if="withErrors">
      <li v-for="(error, index) in errors" :key="index">{{error}}</li>
    </ul>
    <!-- <button type="button" class="btn-clean" v-if="isCleared" @click="clean" row="cn fs" tabindex="-1">
      X
    </button> -->
  </div>
</template>

<script>
  import isEmpty from 'lodash/isEmpty'

  export default {
    name: "MfcInputBox",
    props: {
      errors: {
        type: Array
      },
      clean: {
        type: Function
      },
      isCleared: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      withErrors() {
        return !isEmpty(this.errors)
      }
    }
  }
</script>

<style lang="scss" scoped>
  $error-color: rgb(237, 83, 56);

  .input-box {
    position: relative;

    &.input-no-margin {
      input {
        margin: 0 !important;
      }
    }

    &.with-error {
      input:not(:focus) {
        border-color: $error-color;
      }

      .btn-clean {
        color: $error-color;
        background-color: rgba(255, 255, 255, 1);
      }
    }

    &.bordered {
      .btn-clean {
        top: 14px;
        right: 14px;
      }
    }
  }

  // input.filled:invalid ~ .btn-clean {
  //   color: $error-color;
  //   background-color: rgba(255, 255, 255, 1);
  // }

  // пока нет кнопки очистить
  input ~ .btn-clean {
    opacity: 0;
  }

  input.filled:not(:focus) ~ .btn-clean {
    opacity: 0;
  }

  .btn-clean {
    position: absolute;
    top: 10px;
    right: 12px;
    border: none;
    color: rgba(0, 0, 0, 1);
    padding: 0;
    outline: none;
    cursor: pointer;
    font-weight: 900;
    font-size: 18px;
  }

  .errors {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li {
      color: $error-color;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin: 5px 0;
    }
  }
</style>

<style lang="scss">

  .input-box {
    .btn-additional {
      position: absolute;
      padding: 0;

      button, i {
        padding: 0;
        border: unset;
        color: rgb(135, 135, 135);
        margin: 0;
      }

      &.password {
        font-size: 26px;
      }
    }

    &.bordered {
      .btn-additional {
        top: 22px;
        right: 19px;
      }

      /*&.cleared input.filled ~ .btn-additional {*/
      /*  right: 40px;*/
      /*}*/
    }

    input:read-only, input:disabled {
      & ~ .btn-additional {
        color: rgba(117, 115, 111, 1);
        cursor: default;
      }
    }
  }
</style>
